<loading-status></loading-status>
@if (article$ | async; as situation) {

@if (situation.body) {
<ng-scrollbar class="ngr-scrollbar"  visibility="visible">
    <ul class="nav z-1">
        <li class="flex-grow-1">
            <h1>{{situation.title}}</h1>
        </li>
        <li class="">
            <app-header class="__header w-100" [showBack]="true" [article]="situation" [title]="''" [backRoute]="'..'" [contentSet]="'data/creditors'" [followOptions]="followOptions"></app-header>
        </li>
    </ul>
    @if (situation.body) {
    <div class="container-fluid" id="top">
        @if (sections.includes('overview')) {

        @if (situation.body['summary']; as summary) {
        <div class="row">
            <div class="col-sm-6 my-2">
                <h3 class="column-header">SUMMARY</h3>
                <div [innerHTML]="summary.profile"></div>
                <table class="table table-borderless definitions w-100">
                    <tbody>
                        <tr>
                            <td>Situation Type</td>
                            <td class="ps-2">{{ situation.description.split(':')[0] }}</td>
                        </tr>
                        <tr>
                            <td>Organization</td>
                            <td><a [routerLink]="'/organizations/' + summary.entity_id" class="ps-2" target="_blank">{{summary.entity_name}}</a></td>
                        </tr>
                        @if (summary.case_status) {
                        <tr>
                            <td>
                                Status
                            </td>
                            <td class="ps-2">{{ summary.case_status }}</td>
                        </tr>
                        }
                        @for (timepoint of situation.body['json_overview']?.date_timeline; track timepoint) {

                        <tr>
                            <td>
                                {{ timepoint.type.replace('Bankruptcy Date', 'Petition Date')}}
                            </td>
                            <td class="ps-2">{{ timepoint.event_date | date}}</td>
                        </tr>

                        }
                        @if (summary.case_number) {
                        <tr>
                            <td>
                                Case number
                            </td>
                            <td class="ps-2">
                                <a href="/situations/{{situation.id}}#99" target="_blank">{{ summary.case_number }}</a>
                            </td>
                        </tr>
                        }
                        @if (summary.lead_case_number) {
                        <tr>
                            <td>
                                Lead case
                            </td>
                            <td class="ps-2">
                                <a href="/situations/{{summary.lead_case_situation}}" target="_blank">{{ summary.lead_case_name }}: {{ summary.lead_case_number }}</a>
                            </td>
                        </tr>
                        }
                        @if (summary.full_case_number) {
                        <tr>
                            <td>
                                Full case number
                            </td>
                            <td class="ps-2">{{ summary.full_case_number }}</td>
                        </tr>
                        }
                        @if (summary.case_filing_type) {
                        <tr>
                            <td>
                                Filing Type
                            </td>
                            <td class="ps-2">
                                <a [routerLink]="'/data/creditors'" [queryParams]="{situation_type: 'bankruptcy', case_filing_type: situation.body['case_filing_type']}" queryParamsHandling="merge">
                                    {{ summary.case_filing_type }}
                                </a>
                            </td>
                        </tr>
                        }
                        @if (summary.court_name) {
                        <tr>
                            <td>
                                Filing court &amp; city
                            </td>
                            <td class="ps-2">
                                <a [routerLink]="'/data/creditors'" [queryParams]="{situation_type: 'bankruptcy', court_name: summary['ourCourtID'] + ':' + summary.court_name.replace('Bankruptcy Court', '')}" queryParamsHandling="merge">
                                    {{ summary.court_name }} &ndash; {{ summary.filing_city }}
                                </a>
                            </td>
                        </tr>
                        }
                        @if (situation.body['json'].judges?.length == 1) {
                        <tr>
                            <td>
                                Judge
                            </td>
                            @if (situation.body['json'].judges[0]; as judge) {
                            <td class="ps-2">
                                <a target="_blank" href="/people/{{judge.entity_id}}" class="p-0">{{judge.entity_name}}

                                </a>
                            </td>
                            }
                        </tr>
                        }
                        @if (situation.body['json'].judges?.length == 2) {
                        <tr>
                            <td>
                                Judges
                            </td>
                            <td class="ps-2">
                                <table>
                                    <tbody>
                                        @if (situation.body['json'].judges; as judge) {
                                        <tr>
                                            <td>
                                                <a target="_blank" href="/people/{{judge.entity_id}}" class="p-0">{{judge.entity_name}}</a>
                                            </td>
                                            <td>{{judge.startDate | date}}</td>
                                            <td>{{judge.endDate | date}}</td>
                                        </tr>
                                        }
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        }
                        @if (summary.petition_signers) {
                        <tr>
                            <td>
                                Petition Signers
                            </td>
                            <td class="ps-2">{{ summary.petition_signers }}</td>
                        </tr>
                        }
                        @if (summary.case_industry) {
                        <tr>
                            <td>
                                Industry
                            </td>
                            <td class="ps-2">
                                <a [routerLink]="'/data/creditors'" [queryParams]="{case_industry: summary['case_industry']}" queryParamsHandling="merge">
                                    {{ summary.case_industry }}
                                </a>
                            </td>
                        </tr>
                        }
                        @if (summary.sic_category) {
                        <tr>
                            <td>
                                SIC
                            </td>
                            <td class="ps-2">
                                <a [routerLink]="'/data/creditors'" [queryParams]="{sic: summary.sic  + ':' +  summary.sic_category}" queryParamsHandling="merge">
                                    {{ summary.sic_category }} ({{ summary.sic }})
                                </a>
                            </td>
                        </tr>
                        }
                        @if (summary.assets_range?.length > 3) {
                        <tr>
                            <td>
                                Assets
                            </td>
                            @if (summary.assets_amount && summary.assets_amount.length > 3) {
                            <td class="ps-2">${{ summary.assets_amount }}</td>
                            } @else {

                            <td class="ps-2">{{ summary.assets_range }}</td>

                            }
                        </tr>
                        }
                        @if (summary.liabilities_range?.length > 3) {
                        <tr>
                            <td>
                                Liabilities
                            </td>
                            @if (summary.liabilities_amount && summary.liabilities_amount != '') {
                            <td class="ps-2">${{ summary.liabilities_amount }}</td>
                            } @else {

                            <td class="ps-2">{{ summary.liabilities_range }}</td>

                            }
                        </tr>
                        }
                        @if (summary.creditors_range) {
                        <tr>
                            <td>
                                Creditors
                            </td>
                            <td class="ps-2">{{ summary.creditors_range }}</td>
                        </tr>
                        }
                        @if (summary.employees_range) {
                        <tr>
                            <td>
                                Employees
                            </td>
                            <td class="ps-2">{{ summary.employees_range }}</td>
                        </tr>
                        }
                        @if (summary.revenue_range) {
                        <tr>
                            <td>
                                Revenues
                            </td>
                            <td class="ps-2">{{ summary.revenue_range }}</td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            @if (situation.body['jacs']; as jacs) {

            @if (jacs.length) {
            <div class="col-sm-6 my-2">
                <h3 class="column-header">JOINT ADMINISTERED CASES</h3>
                <table class="table table-borderless definitions w-100">
                    <tbody>
                        @for (jac of jacs; track jac; let i = $index) {
                        <tr [class.d-none]="!showAll['jacs'] && i >= 10">
                            <td>{{jac.case_filing_date | date}}</td>
                            <td>{{jac.case_number}}</td>
                            <td><a [routerLink]="'/situations/' + jac.case_id" queryParamsHandling="merge">{{jac.case_name}}</a></td>
                        </tr>
                        }
                        @if (jacs.length > 10) {
                        <tr>
                            <td colspan="2" class="py-2">
                                <button type="button" class="btn btn-outline-dark btn-sm" (click)="showAll['jacs'] = !showAll['jacs']">
                                    @if (!showAll['jacs']) {

                                    Show All ({{jacs.length}})

                                    }
                                    @if (showAll['jacs']) {

                                    Hide

                                    }
                                </button>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            }

            }
            @if (situation.body['chapter22']; as chapter22) {

            @if (chapter22.length) {
            <div class="col-sm-6 my-2">
                <h3 class="column-header">Chapter 22/33</h3>
                <table class="table table-borderless definitions w-100">
                    <tbody>
                        @for (chap22 of chapter22; track chap22; let i = $index) {
                        <tr>
                            <td>{{chap22.case_filing_date | date}}</td>
                            <td>{{chap22.case_number}}</td>
                            <td><a [routerLink]="'/situations/' + chap22.case_id" queryParamsHandling="merge">{{chap22.case_name}}</a></td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            }

            }
            @if (situation.body['other_situations'].length) {
            <div class="col-sm-6 my-2">
                @if (situation.body['other_situations']; as otherSituations) {

                <h3 class="column-header">OTHER SITUATIONS</h3>
                <table class="table table-borderless definitions w-100">
                    <tbody>
                        @for (other of otherSituations; track other; let i = $index) {

                        <tr>
                            <td>{{other.publicationDate}}</td>
                            <td><a [routerLink]="'/situations/' + other.id" queryParamsHandling="merge">{{other.extras.situation_type}}</a></td>
                        </tr>

                        }
                    </tbody>
                </table>

                }
            </div>
            }
        </div>
        }

        @if (situation.body['json_overview']; as jsonOverview) {
        <div class="row">
            @if (jsonOverview.status_text?.length > 10) {
            <div class="col-12" [innerHTML]="jsonOverview.status_text"></div>
            }
            @if (jsonOverview.background?.length > 10) {
            <div class="col-12" [innerHTML]="jsonOverview.background"></div>
            }
            @if (jsonOverview.about_the_debtors?.length > 10) {
            <div class="col-12" [innerHTML]="jsonOverview.about_the_debtors"></div>
            }
        </div>
        }

        }
    </div>
    }
    <div class="container-fluid">
        <h3 id="topcreditors" class="column-header">CREDITORS</h3>
        @if (situationHasVerifiedTopCreditors(situation)) {
        <table class="table pagetable">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Claim Type</th>
                    <th>C/U/D</th>
                    <th>Amount</th>
                    <th>Locations &amp; Contacts</th>
                </tr>
            </thead>
            <tbody>
                @for (creditor of situation.body['json_topcreditors'].topCreditors; let i = $index;; track creditor) {
                <tr>
                    <td>#{{i + 1}}</td>
                    @if (creditor.entity_type == 'organisation') {
                    <td><a [routerLink]="'/organizations/' + creditor.entity_id" queryParamsHandling="merge">{{creditor.entity_name}}</a></td>
                    }
                    @if (creditor.entity_type == 'person') {
                    <td><a [routerLink]="'/people/' + creditor.entity_id" queryParamsHandling="merge">{{creditor.entity_name}}</a></td>
                    }
                    <td>{{creditor.claim_type}}</td>
                    <td>{{creditor.debt_type}}</td>
                    <td class="text-end">
                        @if (creditor.unsecured_claim_amount_unknown) {

                        Unknown

                        } @else {

                        {{ creditor.amount | currency }}
                        @if (creditor.partially_secured) {

                        (partially secured)

                        }

                        }
                    </td>
                    <td>
                        @if (creditor.locations?.length) {
                        <ul class="list-unstyled">
                            @for (location of creditor.locations; track location) {
                            <li>
                                <address>
                                    {{location.address_display.full_address}}
                                </address>
                            </li>
                            }
                        </ul>
                        }
                        @if (creditor.contacts?.length) {
                        <ul class="list-unstyled">
                            @for (contact of creditor.contacts; track contact) {
                            <li>
                                {{contact.person_name}}
                                <ul class="list-unstyled">
                                    @if (contact.personData?.phone?.length) {
                                    <li><a target="_blank" href="tel:{{contact.personData.phone | phone:'US'}}"><i-bs [name]="iconNames.TelephoneOutbound"></i-bs>&nbsp;{{contact.personData.phone}}</a></li>
                                    }
                                    @if (contact.personData?.email_address?.length) {
                                    <li><a target="_blank" href="email:{{contact.personData.email_address}}"><i-bs [name]="iconNames.EnvelopeAt"></i-bs>&nbsp;{{contact.personData.email_address}}</a></li>
                                    }
                                    @if (contact.personData?.website?.length) {
                                    <li><a target="_blank" href="{{contact.personData.website}}"><i-bs [name]="iconNames.BrowserChrome"></i-bs>&nbsp;{{contact.personData.website}}</a>
                                    </li>
                                    }
                                </ul>

                            </li>
                            }
                        </ul>
                        }
                    </td>
                </tr>
                }
            </tbody>
        </table>
        <court-documents [documents]="situation.body['json_topcreditors'].dockets" colsDefn="col-12"></court-documents>
        } @else {
        <table class="table pagetable">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Claim Type</th>
                    <th class="text-end">Amount</th>
                    <th>Locations &amp; Contacts</th>
                    <th>Provenance</th>
                </tr>
            </thead>
            <tbody>
                @for (creditor of situation.body['allcreditors']; track creditor.id) {
                    <tr>
                        <td>
                            @if (creditor.entityID > 0) {
                                <followable-link area="organizations" [articleid]="creditor.entityID">{{creditor.creditorName}}</followable-link>
                            } @else {
                                {{creditor.creditorName}}
                            }
                        </td>
                        <td>{{creditor.claimType}}</td>
                        <td class="text-end">{{ creditor.totalClaimAmount | currency }}</td>
                        <td>
                            {{ creditor.creditorAddress_address1 }} {{ creditor.creditorAddress_address2 }} {{ creditor.creditorAddress_city }} {{ creditor.creditorAddress_state }} {{ creditor.creditorAddress_zip }} {{ creditor.creditorAddress_country }} <br>
                            {{creditor.contactName}}
                            <ul class="list-unstyled my-0">
                                @if (creditor.contactTelephone?.length) {
                                <li><a target="_blank" href="tel:{{creditor.contactTelephone | phone:'US'}}"><i-bs [name]="iconNames.TelephoneOutbound"></i-bs>&nbsp;{{creditor.contactTelephone}}</a></li>
                                }
                                @if (creditor.contactFax?.length) {
                                <li><a target="_blank" href="fax:{{creditor.contactFax | phone:'US'}}">
                                        Fax&nbsp;{{creditor.fax}}</a></li>
                                }
                                @if (creditor.contactEmail?.length) {
                                <li><a target="_blank" href="email:{{creditor.contactEmail}}"><i-bs [name]="iconNames.EnvelopeAt"></i-bs>&nbsp;{{creditor.contactEmail}}</a></li>
                                }
                            </ul>
                        </td>
                        <td>
                            @switch (creditor.form) {
                                @case ('form206ef') {
                                    Form 206/EF
                                }
                                @case ('form204') {
                                    Form 204
                                }
                                @case ('form206d') {
                                    Form 206D
                                }
                            }
                        </td>
                    </tr>
                }
            </tbody>
        </table>

        }
    </div>


    <div class="row m-2">
    </div>
</ng-scrollbar>
}

}

<ng-template #linkedIn let-linkedin="linkedin">
    <linked-in [handle]="linkedin"></linked-in>
</ng-template>

<ng-template #displayContact let-contact="contact">
    <ul class="list-unstyled">
        <li><a target="_blank" href="{{contact.url}}">{{ contact.entity_name }}</a></li>
        @if (contact.telephone) {
        <li><a target="_blank" href="tel:{{contact.telephone}}"><i-bs [name]="iconNames.TelephoneOutbound"></i-bs>&nbsp;{{contact.telephone | phone:'US'}}</a></li>
        }
        @if (contact.email) {
        <li><a target="_blank" href="email:{{contact.email}}"><i-bs [name]="iconNames.EnvelopeAt"></i-bs>&nbsp;{{contact.email}}</a></li>
        }
        @if (contact.web) {
        <li><a target="_blank" [href]="contact.web"><i-bs [name]="iconNames.BrowserChrome"></i-bs>&nbsp;Website</a></li>
        }
        @if (contact.linkedin?.length > 10) {
        <li>
            <a target="_blank" [href]="contact.linkedin" [ngbPopover]="linkedIn" [popoverContext]="{linkedin : getLinkedInHandle(contact.linkedin)}" popoverClass="linkedin-popover" triggers="mouseenter" [autoClose]="'outside'" [openDelay]="200">
                <i-bs [name]="iconNames.Linkedin"></i-bs>&nbsp;LinkedIn
            </a>
        </li>
        }
    </ul>
</ng-template>


<ng-template #entityWithEmployeesTable let-entities="entities" let-prefix="prefix" let-colspan="colspan">
    <table class="table entity-table w-100">
        <thead>
            <th [attr.colspan]="colspan">Company</th>
            <th>Role</th>
            <th>Start Date</th>
            <th>End Date</th>
        </thead>
        <tbody>
            @for (tr of entities; track tr; let i = $index) {

            @if (showAll[prefix] || i <= 10) { @if (tr.role==='blank' ) { <tr>
                <td colspan="5">
                    <p></p>
                </td>
                </tr>

                } @else {

                <tr>
                    @if (tr.employees?.length) {
                    <td (click)="isExpanded[prefix + i] = ! isExpanded[prefix + i]" class="expanding" style="width: 25px;">
                        <i-bs [name]="iconNames.ChevronDown" [@openClose]="isExpanded[prefix + 1] ? 'open' : 'closed'" class="d-inline-block"></i-bs>
                    </td>
                    }
                    @if (tr.employees?.length === 0) {
                    <td></td>
                    }
                    <td>
                        <a [routerLink]="tr.url" target="_blank">{{ tr.entity_name }}</a>
                    </td>
                    @if (tr.role.length) {
                    <td> {{tr.role}}</td>
                    }
                    @if (tr.role.length === 0) {
                    <td> {{tr.type}} / {{tr.subtype}}</td>
                    }
                    <td> {{tr.start_date}}</td>
                    <td> {{tr.end_date}}</td>
                </tr>
                @if (tr.employees?.length && isExpanded[prefix + i]) {
                <tr @expandCollapse>
                    <td colspan="100">
                        <div class="collapsible">
                            <ng-container *ngTemplateOutlet="showEmployees; context: {row: tr}"></ng-container>
                        </div>
                    </td>
                </tr>
                }

                }

                }

                }
                @if (entities.length > 10) {
                <tr>
                    <td colspan="10" class="py-2">
                        <button type="button" class="btn btn-outline-dark btn-sm p-1" (click)="showAll[prefix] = !showAll[prefix]">
                            @if (!showAll[prefix]) {

                            Show All ({{entities.length}})

                            }
                            @if (showAll[prefix]) {

                            Hide

                            }
                        </button>
                    </td>
                </tr>
                }
        </tbody>
    </table>
</ng-template>

<ng-template #showEmployees let-row="row">
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-2 g-2 ms-2  w-100">
        @for (contact of row.employees; track contact) {
        <div class="col">
            <div class="card h-100 mx-1">
                <div class="card-body">
                    <p class="card-text">
                        <ng-container *ngTemplateOutlet="displayContact; context: {contact}"></ng-container>
                    </p>
                </div>
            </div>
        </div>
        }
    </div>
</ng-template>

<ng-template #showAdvisorsWithEmployees let-advisors="value" let-i="i">
    @for (advisor of advisors; track advisor) {

    @if (!advisor.hidden) {
    <p (click)="isExpanded[advisor.entity_name + i ] = ! isExpanded[advisor.entity_name + i ]">
        <i-bs [name]="iconNames.ChevronDown" [@openClose]="isExpanded[advisor.entity_name + i ] ? 'open' : 'closed'" class="d-inline-block"></i-bs>
        {{advisor.entity_name}} ({{advisor.role}} @if (advisor.subtype!='Employee') { — }{{advisor.subtype}})
    </p>
    @if (expandAll || isExpanded[advisor.entity_name + i ]) {
    <div @expandCollapse>
        <div class="collapsible">
            <div class="row row-cols-1 g-2 ms-2 w-100">
                @for (contact of advisor.employees; track contact) {
                <div class="col">
                    <div class="card h-100 mx-1">
                        <div class="card-body">
                            <p class="card-text">
                                <ng-container *ngTemplateOutlet="displayContact; context: {contact}"></ng-container>
                            </p>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
    }
    }
    }
</ng-template>

<ng-template #rawCreditorRow let-creditor="creditor" let-secured="secured">
    <tr>
        <td>{{creditor.creditorName}}</td>
        <td>{{creditor.claimType}}</td>
        <td class="text-end">{{ creditor.amount | currency }}</td>
        <td>
            {{ creditor.building }} {{ creditor.street }} {{ creditor.city }} {{ creditor.state }} {{ creditor.zip }} {{ creditor.country }} <br>
            {{creditor.contact}}
            <ul class="list-unstyled my-0">
                @if (creditor.phone?.length) {
                <li><a target="_blank" href="tel:{{creditor.phone | phone:'US'}}"><i-bs [name]="iconNames.TelephoneOutbound"></i-bs>&nbsp;{{creditor.phone}}</a></li>
                }
                @if (creditor.fax?.length) {
                <li><a target="_blank" href="fax:{{creditor.fax | phone:'US'}}">
                        Fax&nbsp;{{creditor.fax}}</a></li>
                }
                @if (creditor.email?.length) {
                <li><a target="_blank" href="email:{{creditor.email}}"><i-bs [name]="iconNames.EnvelopeAt"></i-bs>&nbsp;{{creditor.email}}</a></li>
                }
            </ul>
        </td>
        <td>{{ secured }}</td>
    </tr>
</ng-template>

<ng-template #displayRepresentative let-representative="representative">
    <div>
        <div class="mb-3">
            @if (representative.name) {
            {{ representative.name }}<br>
            }
            @if (representative.firm) {
            {{ representative.firm }}<br>
            }
            @if (representative.address || representative.city || representative.state || representative.zip) {
            @if (representative.address) {
            {{representative.address}},
            }
            @if (representative.city) {
            {{representative.city}},
            }
            @if (representative.state) {
            {{representative.state}},
            }
            @if (representative.zip) {
            {{representative.zip}}
            }
            <br>
            }
            @if (representative.phone) {
            Phone: <a target="_blank" href="tel:{{representative.phone | phone:'US'}}"><i-bs [name]="iconNames.TelephoneOutbound"></i-bs>&nbsp;{{representative.phone | phone:'US'}}</a><br>
            }
            @if (representative.email) {
            Email: <a target="_blank" href="email:{{representative.email}}"><i-bs [name]="iconNames.EnvelopeAt"></i-bs>&nbsp;{{representative.email}}</a>
            }
        </div>
    </div>

</ng-template>
