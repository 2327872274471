import { Component, Input } from '@angular/core'
import { Creditor } from '../creditor-article.model'
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons'
import { PhoneFormatPipe } from 'src/app/shared/pipes/phone-format.pipe'
import { LinkedInComponent } from 'src/app/shared/components/linked-in/linked-in.component'
import { RouterLink } from '@angular/router'
import { SituationComponent } from 'src/app/pages/situations'
import { LoadingStatusComponent } from 'src/app/shared/components/loading-status/loading-status.component'
import { NgScrollbarModule } from 'ngx-scrollbar'
import { HeaderComponent } from 'src/app/shared/components/detail-header/detail-header.component'
import { AsyncPipe, CurrencyPipe, DatePipe, NgTemplateOutlet } from '@angular/common'
import { CourtDocumentsComponent } from 'src/app/shared/components/court-documents/court-documents'
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap'
import { FollowableLinkComponent } from 'src/app/shared/components/followable-link/followable-link.component'

@Component({
  selector: 'app-creditor-article',
  standalone: true,
  imports: [
    LoadingStatusComponent,
    NgScrollbarModule,
    NgxBootstrapIconsModule,
    HeaderComponent,
    LinkedInComponent,
    NgbPopover,
    PhoneFormatPipe,
    AsyncPipe,
    DatePipe,
    CurrencyPipe,
    RouterLink,
    CourtDocumentsComponent,
    NgTemplateOutlet,
    FollowableLinkComponent
  ],
  templateUrl: './creditor-article.component.html',
  styleUrl: './creditor-article.component.scss'
})
export class CreditorArticleComponent extends SituationComponent {
  @Input() article: Creditor | undefined = undefined

}
